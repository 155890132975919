import React, { useState } from "react"
import tw, { styled, css } from "twin.macro"
import Layout from "../components/layout"
import { AiOutlinePlus } from "react-icons/ai"
import { AiOutlineMinus } from "react-icons/ai"
import { StaticImage } from "gatsby-plugin-image"
import SectionHeader from "../components/SectionHeader"
import { motion } from "framer-motion"
import { Link, useTranslation, Trans } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["faq"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const FAQSection = () => {
  const [category, setCategory] = useState('studio')
  const { t } = useTranslation();

  return (
    <Layout>
      <Helmet>
        <title>FAQ - Najczęściej zadawane pytania i odpowiedzi - Nemezink Tattoo Studio</title>
        <meta name="description" content="Nurtują Cię pytania odnośnie wykonania tatuażu lub innych naszych usług? Zachęcamy do odwiedzenia naszej podstrony z sekcją FAQ!" />
      </Helmet>
      <Wrapper>
        <StaticImage
          src="../images/faqBg.png"
          alt="tattoo process"
          quality={30}
          css={css`
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
            @media (max-width: 1024px) {
              position: absolute;
            }
          `}
        />
        <FAQtext
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.1 }}
          transition={{ duration: 0.5 }}
        >
          FAQ
        </FAQtext>
        <SectionHeader
          tag={<Trans>faqTag</Trans>}
          size="md"
          text={<Trans>faqHeader</Trans>}
          emphasizedText={<Trans>faqHeaderSub</Trans>}
          emphasizedTag="h1"
        />
        <div tw="flex flex-row gap-x-8 mt-8">
          <CategoryButton active={category === 'studio'} onClick={() => setCategory('studio')}><h2><Trans>studioBtn</Trans></h2></CategoryButton>
          <CategoryButton active={category === 'tattoo'} onClick={() => setCategory('tattoo')}><h2><Trans>tattooBtn</Trans></h2></CategoryButton>
          <CategoryButton active={category === 'piercing'} onClick={() => setCategory('piercing')}><h2><Trans>piercingBtn</Trans></h2></CategoryButton>
        </div>
        <FAQContainer>
          {FAQs[category].map((item) => (
            <FAQ question={item[0]} answer={item[1]} />
          ))}
        </FAQContainer>
      </Wrapper>
    </Layout>
  )
}

export default FAQSection

const FAQ = ({ question, answer }) => {
  const [showFaq, setShowFaq] = useState(false)
  const faqVariants = {
    collapsed: {
      height: 0,
    },
    expanded: {
      height: "auto",
    },
  }
  return (
    <FAQWrapper onClick={e => { e.stopPropagation(); setShowFaq(!showFaq) }}>
      <div tw="flex flex-row items-center justify-between w-full">
        <Question>{question}</Question>
        <ExpandIcon expanded={showFaq} />
        <CollapseIcon expanded={showFaq} />
      </div>
      <motion.div
        variants={faqVariants}
        initial="collapsed"
        animate={showFaq ? "expanded" : "collapsed"}
        transition={{ duration: 0.3, ease: "easeIn" }}
      >
        <Answer>{answer}</Answer>
      </motion.div>
    </FAQWrapper>
  )
}

const CategoryButton = styled.button`
${tw`mb-8`}
  font-weight: ${({ active }) => active ? 'bold' : 'normal'};
  text-decoration: ${({ active }) => active ? 'underline' : 'none'};
  color: #fbfbfb;
  outline: 0 !important;
  font-size: 1.2vw;
  @media (max-width: 1024px) {
    font-size: 2.5vw;
  }
  @media (max-width: 760px) {
    font-size: 3.7vw;
  }

  & > h2 {
    font-size: inherit;
  }
`

const Wrapper = styled.div`
  ${tw`relative float-left h-full w-full m-0 bg-fixed overflow-hidden bg-fixed bg-cover`}
  ${tw`flex flex-col`}
  padding: 9vw 11vw 7vw 11vw;
  font-family: "Space Mono";
  font-style: normal;
  font-weight: normal;
  @media (max-width: 1024px) {
    padding: 24.5vw 5vw 7.5vw 5vw;
  }
  @media (max-width: 760px) {
    padding: 30vw 4vw 9vw 4vw;
  }
`


const FAQtext = styled(motion.div)`
  ${tw`absolute`}
  top: 9vw;
  right: 7vw;
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: bold;
  font-size: 21vw;
  line-height: 120%;
  letter-spacing: 0.1em;
  color: rgba(255, 255, 255);
  text-transform: uppercase;
  user-select: none;
  pointer-events: none;
  @media (max-width: 1024px) {
    top: 35vw;
    right: 5vw;
    font-size: 35vw;
    white-space: nowrap;
  }
  @media (max-width: 760px) {
    top: 55vw;
    left: 42vw;
    font-size: 40vw;
    white-space: nowrap;
  }
`

const FAQContainer = styled.div`
  ${tw`flex flex-col w-full`}
  &:last-child {
    border-bottom: 1px solid #e7e7e7;
  }
`

const FAQWrapper = styled.div`
  ${tw`flex flex-col  w-full`}
  border-top:1px solid #e7e7e7;
  padding: 0vw 2.5vw 0vw 2.5vw;
  cursor: pointer;
  @media (max-width: 1024px) {
  }
`

const Question = styled.h3`
  margin-bottom: 0px;
  display: inline-block;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: bold;
  color: #fbfbfb;
  user-select: none;
  pointer-events: none;
  padding: 1.5vw 0vw;
  font-size: 1.1vw;
  @media (max-width: 1024px) {
    font-size: 2vw;
    padding: 3vw 0vw;
  }
  @media (max-width: 760px) {
    font-size: 3vw;
    padding: 4.8vw 0vw;
  }
`

const Answer = styled.span`
  display: block;
  max-height: 50%;
  font-family: "Space Mono";
  font-style: normal;
  font-weight: normal;
  color: rgba(251, 251, 251, 0.75);
  user-select: none;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  padding-right: 2vw;
  font-size: 0.9vw;
  margin-bottom: 1vw;
  pointer-events: none;
  @media (max-width: 1024px) {
    font-size: 2vw;
  }
  @media (max-width: 760px) {
    font-size: 3.4vw;
  }
`

const ExpandIcon = styled(AiOutlinePlus)`
  font-size: 1.3vw;
  color: #fbfbfb;
  display: ${({ expanded }) => (expanded ? "none" : "block")};
  opacity: ${({ expanded }) => (expanded ? 0 : 1)};
  transition: opacity 1s ease-in-out;
  pointer-events: none;
  @media (max-width: 1024px) {
    font-size: 4.2vw;
  }
`

const CollapseIcon = styled(AiOutlineMinus)`
  font-size: 1.3vw;
  color: #fbfbfb;
  display: ${({ expanded }) => (expanded ? "block" : "none")};
  transition: opacity 1s ease-in-out;
  opacity: ${({ expanded }) => (expanded ? 1 : 0)};
  pointer-events: none;
  @media (max-width: 1024px) {
    font-size: 4.2vw;
  }
`


const FAQs = {
  studio: [
    [<Trans>studioQuestion1</Trans>, <Trans>studioAnswer1</Trans>],
    [<Trans>studioQuestion2</Trans>, <Trans>studioAnswer2</Trans>],
    [<Trans>studioQuestion3</Trans>, <Trans>studioAnswer3</Trans>],
    [<Trans>studioQuestion4</Trans>, <Trans>studioAnswer4</Trans>],
    [<Trans>studioQuestion5</Trans>, <Trans>studioAnswer5</Trans>],
    [<Trans>studioQuestion6</Trans>, <Trans>studioAnswer6</Trans>],
    [<Trans>studioQuestion7</Trans>, <Trans>studioAnswer7</Trans>]],
  tattoo: [
    [[<Trans>tattooQuestion1</Trans>], [<Trans>tattooAnswer1</Trans>]],
    [[<Trans>tattooQuestion2</Trans>], [<Trans>tattooAnswer2</Trans>]],
    [[<Trans>tattooQuestion3</Trans>], [<Trans>tattooAnswer3</Trans>]],
    [[<Trans>tattooQuestion4</Trans>], [<Trans>tattooAnswer4</Trans>]],
    [[<Trans>tattooQuestion5</Trans>], [<Trans>tattooAnswer5</Trans>]],
    [[<Trans>tattooQuestion6</Trans>], [<Trans>tattooAnswer6</Trans>]],
    [[<Trans>tattooQuestion7</Trans>], [<Trans>tattooAnswer7</Trans>]],
    [[<Trans>tattooQuestion8</Trans>], [<Trans>tattooAnswer8</Trans>]],
    [[<Trans>tattooQuestion9</Trans>], [<Trans>tattooAnswer9</Trans>]],
    [[<Trans>tattooQuestion10</Trans>], [<Trans>tattooAnswer10</Trans>]],
    [[<Trans>tattooQuestion11</Trans>], [<Trans>tattooAnswer11</Trans>]],
    [[<Trans>tattooQuestion12</Trans>], [<Trans>tattooAnswer12</Trans>]],
    [[<Trans>tattooQuestion13</Trans>], [<Trans>tattooAnswer13</Trans>]],
    [[<Trans>tattooQuestion14</Trans>], [<Trans>tattooAnswer14</Trans>]],
    [[<Trans>tattooQuestion15</Trans>], [<Trans>tattooAnswer15</Trans>]],
    [[<Trans>tattooQuestion16</Trans>], [<Trans>tattooAnswer16</Trans>]]
  ],
  piercing: [
    [[<Trans>piercingQuestion1</Trans>], [<Trans>piercingAnswer1</Trans>]],
    [[<Trans>piercingQuestion2</Trans>], [<Trans>piercingAnswer2</Trans>]],
    [[<Trans>piercingQuestion3</Trans>], [<Trans>piercingAnswer3</Trans>]],
    [[<Trans>piercingQuestion4</Trans>], [<Trans>piercingAnswer4</Trans>]],
    [[<Trans>piercingQuestion5</Trans>], [<Trans>piercingAnswer5</Trans>]],
    [[<Trans>piercingQuestion6</Trans>], [<Trans>piercingAnswer6</Trans>]],
    [[<Trans>piercingQuestion7</Trans>], [<Trans>piercingAnswer7</Trans>]],
    [[<Trans>piercingQuestion8</Trans>], [<Trans>piercingAnswer8</Trans>]]
  ]
};